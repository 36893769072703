import { store, Model } from "hybrids";
import { get } from "../utils/api";

import User from "./User";

interface Session {
  user: User;
  blockedUsers: User[];
}

const Session: Model<Session> = {
  user: User,
  blockedUsers: [User],
  [store.connect]: () => get("/session"),
};

export default Session;
